import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { AnimatePresence, motion } from "motion/react";
import classNames from "classnames";

import {
    DEFAULT_SCOREBOARD_HEAT_TEAM_DISPLAY,
    SCOREBOARD_HEAT_TEAM_DISPLAY_INVERSE_MAP,
} from "~/shared/constants";
import styles from "~/shared/components/MeetCore/Scoreboard/Scenes/Scenes.less";

const getShortenedName = (name) => {
    if (!name) return "";

    const nameParts = name.trim().split(/\s+/);

    if (nameParts.length === 1) return name;

    return `${nameParts[0][0]}. ${nameParts.slice(1).join(" ")}`;
};

function TableRow({
    lane = "",
    swimmerName = "",
    teamName = "",
    teamLogo = "",
    place = null,
    time = "",
    hasFinished = false,
    teamDisplay = DEFAULT_SCOREBOARD_HEAT_TEAM_DISPLAY,
    showLaneTimes = true,
    hideSwimmer = false,
    viewportFit = false,
}) {
    const [shouldShorten, setShouldShorten] = useState(false);
    const [teamLogoSize, setTeamLogoSize] = useState(0);

    const nameRef = useRef(null);
    const rowRef = useRef(null);

    useEffect(() => {
        if (!nameRef.current) return;

        const element = nameRef.current;
        let frameId = null;

        const checkTruncation = () => {
            frameId = requestAnimationFrame(() => {
                if (!element) return;
                const isTruncated = element.scrollWidth > element.clientWidth;
                setShouldShorten(isTruncated);
            });
        };

        setShouldShorten(false);
        requestAnimationFrame(checkTruncation);

        // eslint-disable-next-line consistent-return
        return () => {
            if (frameId) {
                cancelAnimationFrame(frameId);
            }
        };
    }, [swimmerName]);

    useEffect(() => {
        const updateLogoSize = () => {
            if (rowRef.current) {
                setTeamLogoSize(rowRef.current.clientHeight - 20);
            }
        };

        const observer = new ResizeObserver(updateLogoSize);

        if (rowRef.current) {
            observer.observe(rowRef.current);
        }

        return () => observer.disconnect();
    }, []);

    function getFormattedName(name) {
        return shouldShorten ? getShortenedName(name) : name;
    }

    function renderTeamLogo() {
        if (teamDisplay !== SCOREBOARD_HEAT_TEAM_DISPLAY_INVERSE_MAP.logo) {
            return null;
        }

        return (
            <td
                className={styles.cellTeamLogo}
                style={{
                    width: `${teamLogoSize}px`,
                }}
            >
                {teamLogo ? (
                    <div
                        className="c-avatar c-avatar--scoreboard c-avatar--circle"
                        style={{
                            width: `${teamLogoSize}px`,
                            maxWidth: `${teamLogoSize}px`,
                            height: `${teamLogoSize}px`,
                        }}
                    >
                        <img src={teamLogo} alt={`${teamName} logo`} />
                    </div>
                ) : null}
            </td>
        );
    }

    function renderTeam() {
        if (
            hideSwimmer ||
            teamDisplay !== SCOREBOARD_HEAT_TEAM_DISPLAY_INVERSE_MAP.name
        ) {
            return null;
        }

        if (viewportFit) {
            return <td className={styles.cellTeam}>{teamName}</td>;
        }

        return (
            <div className={`${styles.cellTeam} u-text-truncate`}>
                {teamName || <div className="u-is-invisible">No team</div>}
            </div>
        );
    }

    return (
        <motion.tr
            ref={rowRef}
            variants={{
                initial: { opacity: 0 },
                show: { opacity: 1 },
                exit: { opacity: 0 },
            }}
        >
            <td className={styles.cellLane}>{lane}</td>
            {renderTeamLogo()}
            {viewportFit ? (
                <>
                    <td ref={nameRef} className={styles.cellPrimary}>
                        {hideSwimmer ? teamName : getFormattedName(swimmerName)}
                    </td>
                    {renderTeam()}
                </>
            ) : (
                <td className={styles.cellPrimary}>
                    {hideSwimmer ? (
                        teamName
                    ) : (
                        <>
                            <div ref={nameRef} className="u-text-truncate">
                                {swimmerName ? (
                                    getFormattedName(swimmerName)
                                ) : (
                                    <div className="u-is-invisible">
                                        No swimmer
                                    </div>
                                )}
                            </div>
                            {renderTeam()}
                        </>
                    )}
                </td>
            )}
            <td
                className={classNames({
                    [styles.cellPlace]: true,
                    [styles.cellPlaceFinished]: hasFinished,
                    [styles.cellInvisible]: !showLaneTimes,
                })}
            >
                {place}
            </td>
            <td
                className={classNames({
                    [styles.cellResult]: true,
                    [styles.cellInvisible]: !showLaneTimes,
                })}
            >
                <AnimatePresence>
                    {time ? (
                        <motion.div
                            className="u-text-truncate"
                            initial={{ y: 100, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            exit={{ y: 100, opacity: 0 }}
                            transition={{ ease: "easeOut", duration: 0.3 }}
                        >
                            {time}
                        </motion.div>
                    ) : null}
                </AnimatePresence>
            </td>
        </motion.tr>
    );
}

TableRow.propTypes = {
    lane: PropTypes.string,
    swimmerName: PropTypes.string,
    teamName: PropTypes.string,
    teamLogo: PropTypes.string,
    place: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.bool,
    ]),
    time: PropTypes.string,
    hasFinished: PropTypes.bool,
    teamDisplay: PropTypes.string,
    showLaneTimes: PropTypes.bool,
    hideSwimmer: PropTypes.bool,
    viewportFit: PropTypes.bool.isRequired,
};

export default TableRow;
