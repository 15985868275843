import React, { useMemo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { AnimatePresence, motion } from "motion/react";
import _ from "lodash";

import EventHeader from "~/shared/components/MeetCore/Scoreboard/Scenes/LiveHeat/EventHeader";
import Timer from "~/shared/components/MeetCore/Scoreboard/Scenes/LiveHeat/Timer";
import TableRow from "~/shared/components/MeetCore/Scoreboard/Scenes/LiveHeat/TableRow";
import DivingEventMessage from "~/shared/components/MeetCore/Scoreboard/Scenes/LiveHeat/DivingEventMessage";
import styles from "~/shared/components/MeetCore/Scoreboard/Scenes/Scenes.less";
import { getTeamNameFromSplash } from "~/shared/components/MeetCore/utils";
import {
    DEFAULT_SCOREBOARD_HEAT_TEAM_DISPLAY,
    SCOREBOARD_HEAT_TEAM_DISPLAY_INVERSE_MAP,
    SCOREBOARD_MOTION_TBODY_VARIANTS,
} from "~/shared/constants";
import { decimalToSwim } from "~/shared/utils";

function CtsLiveHeat({
    raceInProgress = false,
    splashByLane = {},
    lanes = {},
    timer = null,
    numLanes = null,
    eventName = "",
    eventNumber = null,
    eventHeatsCount = null,
    heat = null,
    isReady = false,
    isDivingEvent = false,
    showTimer = false,
    teamDisplay = DEFAULT_SCOREBOARD_HEAT_TEAM_DISPLAY,
    showLaneTimes = true,
    hideSwimmer = false,
    hideBodyScroll = false,
    hideHeader = false,
    viewportFit = false,
}) {
    const tableBodyKey = useMemo(() => {
        return [eventName, eventNumber, heat].filter(Boolean).join("-");
    }, [eventName, eventNumber, heat]);

    function renderIsReady() {
        if (!isReady) return null;

        return <div className={styles.headerDot} />;
    }

    function renderTime() {
        if (!showTimer) return null;

        return <Timer>{Boolean(timer) && decimalToSwim(timer)}</Timer>;
    }

    function renderHeader() {
        if (hideHeader) return null;

        return (
            <div className={styles.header}>
                <EventHeader
                    eventName={eventName}
                    eventNumber={eventNumber}
                    heatNumber={heat}
                    eventHeatsCount={eventHeatsCount}
                />
                {raceInProgress ? renderTime() : renderIsReady()}
            </div>
        );
    }

    function renderRow(lane, laneData) {
        const splash = _.first(splashByLane[lane] || []);
        const laneStarted = Boolean(laneData.running || laneData.time);
        const showLane = !raceInProgress || laneStarted;
        const swimmerName =
            showLane && splash && splash.swimmer
                ? splash.swimmer.display_name
                : "";
        const teamName =
            showLane && splash ? getTeamNameFromSplash({ splash }) : "";
        const teamLogo =
            showLane && splash && splash.team ? splash.team.logo : "";
        const place = showLane && (laneData.dq ? "DQ" : laneData.place || null);
        const time =
            showLane && Boolean(laneData.time && !laneData.running)
                ? decimalToSwim(laneData.time)
                : null;
        const hasFinished = Boolean(laneData.place || laneData.dq);

        return (
            <TableRow
                key={lane}
                lane={lane}
                swimmerName={swimmerName}
                teamName={teamName}
                teamLogo={teamLogo}
                place={place}
                time={time}
                hasFinished={hasFinished}
                teamDisplay={teamDisplay}
                showLaneTimes={showLaneTimes}
                hideSwimmer={hideSwimmer}
                viewportFit={viewportFit}
            />
        );
    }

    function renderTable() {
        if (isDivingEvent) return <DivingEventMessage />;

        if (!lanes) return null;

        return (
            <table
                className={classNames({
                    table: true,
                    [styles.table]: true,
                })}
            >
                <AnimatePresence mode="wait">
                    <motion.tbody
                        key={tableBodyKey}
                        variants={SCOREBOARD_MOTION_TBODY_VARIANTS}
                        initial="initial"
                        animate="show"
                        exit="exit"
                    >
                        {_.toPairs(lanes).map(
                            ([lane, laneData]) =>
                                lane <= numLanes && renderRow(lane, laneData),
                        )}
                    </motion.tbody>
                </AnimatePresence>
            </table>
        );
    }

    return (
        <div
            data-scoreboard-window={hideBodyScroll ? "true" : "false"}
            className={classNames(
                viewportFit ? styles.scoreboardFit : styles.scoreboard,
                { [styles[`lanes-${numLanes}`]]: true },
                {
                    [styles.cellTeamHidden]:
                        teamDisplay ===
                            SCOREBOARD_HEAT_TEAM_DISPLAY_INVERSE_MAP.hidden ||
                        teamDisplay ===
                            SCOREBOARD_HEAT_TEAM_DISPLAY_INVERSE_MAP.logo,
                },
            )}
        >
            {renderHeader()}
            <div className={styles.body}>{renderTable()}</div>
        </div>
    );
}

CtsLiveHeat.propTypes = {
    raceInProgress: PropTypes.bool,
    splashByLane: PropTypes.shape({}),
    lanes: PropTypes.shape({}),
    timer: PropTypes.number,
    numLanes: PropTypes.number,
    eventName: PropTypes.string,
    eventNumber: PropTypes.number,
    eventHeatsCount: PropTypes.number,
    heat: PropTypes.number,
    isReady: PropTypes.bool,
    isDivingEvent: PropTypes.bool,
    showTimer: PropTypes.bool,
    teamDisplay: PropTypes.string,
    showLaneTimes: PropTypes.bool,
    hideSwimmer: PropTypes.bool,
    hideHeader: PropTypes.bool,
    hideBodyScroll: PropTypes.bool,
    viewportFit: PropTypes.bool,
};

export default CtsLiveHeat;
